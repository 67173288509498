/* Import Tailwind CSS base styles */
@import "tailwindcss/base";

/* Import Tailwind CSS component styles */
@import "tailwindcss/components";

/* Import Tailwind CSS utility styles */
@import "tailwindcss/utilities";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content-page {
  padding-top: 10%; /* default paddingTop for small screens */
}

@media (min-width: 768px) {
  /* for medium screens */
  .content-page {
    padding-top: 8%;
  }
}

@media (min-width: 992px) {
  /* for large screens */
  .content-page {
    padding-top: 6%;
  }
}

@media (min-width: 1200px) {
  /* for extra large screens */
  .content-page {
    padding-top: 6%;
  }
}

@media (min-width: 1600px) {
  /* for extra large screens */
  .content-page {
    padding-top: 3%;
  }
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.footer {
  margin-top: 20px; /* adjust as needed */
  padding-top: 20px !important; /* adjust as needed */
}

/* Login and Sign up Form */
.center-login {
  margin-right: 5% !important;
  margin-left: auto !important;
}

.carousel-caption-background {
  background-color: rgba(240, 244, 248, 0.6);
  padding: 15x; /* Optional: some padding */
  color: rgb(8, 39, 100) !important;

  font-size: 0.9rem;
  font-weight: 400;
}

/* Button Hightlight*/
.highlighted {
  background-color: #f0f0f0; /* or any other color */
}

/*Custom padding */

.p-custom-2-1 {
  padding: 2.1rem !important;
}

.p-custom-0-0 {
  padding: 0rem !important;
}
.p-custom-0-5 {
  padding: 0.5rem !important;
}
