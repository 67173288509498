.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.nav-link {
  font-weight: 500;
  color: #333;
}

.nav-link.active {
  color: #007bff;
}

.card-title {
  font-size: 0.9rem;
  font-weight: bold;
  color: #6c757d;
  margin-bottom: 0.25rem;
}

.card-text {
  font-size: 1rem;
  font-weight: 500;
}

.screening-steps {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: relative;
}

.screening-steps::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  height: 2px;
  background: #e0e0e0;
  z-index: 1;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
}

.step-indicator {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.step.completed .step-indicator {
  background: #28a745;
  border-color: #28a745;
  color: #fff;
}

.step.current .step-indicator {
  border-color: #007bff;
  color: #007bff;
}

.step-label {
  font-size: 12px;
  text-align: center;
}
