/* editorStyles.css */
.ql-editor {
  direction: ltr !important;
  text-align: left !important;
}

/* Ensure lists are aligned to the left */
.ql-editor ol,
.ql-editor ul {
  padding-left: 20px !important;
  text-align: left !important;
}

.ql-editor ol {
  list-style-position: inside !important;
}

.ql-editor ul {
  list-style-position: inside !important;
}