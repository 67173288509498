.shimmer-wrapper {
  position: relative;
  overflow: hidden;
  background: #f6f7f8;
}

.shimmer-circle,
.shimmer-line,
.shimmer-chart {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: ease-in-out;
}

.shimmer-circle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.shimmer-line {
  height: 16px;
  border-radius: 4px;
  width: 100%;
}

.shimmer-chart {
  width: 100%;
  height: 100%;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.shimmer-wrapper.shimmer-dark .shimmer-circle,
.shimmer-wrapper.shimmer-dark .shimmer-line,
.shimmer-wrapper.shimmer-dark .shimmer-chart {
  background: #2a2a2a;
  background-image: linear-gradient(
    90deg,
    #2a2a2a 0px,
    #3a3a3a 40px,
    #2a2a2a 80px
  );
}

.shimmer-dark {
  background-color: #212529; /* or your preferred dark mode background color */
}
